import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {Link} from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "./popups/askQuestionPopup";
import { AddTrailingSlashToUrl } from "../lib/helpers";

// This component takes 3 parameters:
// the _type of link => to what subdirectory of the site is this link pointing at
// reference of the link => the adress to which the link points, without subdirectories
// children => everything that is supposed to be inside the link, whetever that be text, button or card
//
// eturn <Link to={`/ask/${reference}`}>{children}awsdadws</Link>


const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: "none"
    }
}))


const Container = ({ children, reference, _type, customClassName, logCtaWidgetGoogleAnalyticsEvent, newTab, onClickHandler, queryParams, isMenuItem, logSidebarLinkGoogleAnalyticsEvent, brazeRedirectUrl }) => {
  const classes = useStyles();

  const [ctaWidgetGoogleAnalyticsObject, setCtaWidgetGoogleAnalyticsObject] = useState({});
  const [sidebarLinkGoogleAnalyticsObject, setSidebarLinkGoogleAnalyticsObject] = useState({});


  var target = "_self";

  if (typeof newTab == "undefined" || newTab == false || newTab == undefined) {
    target = "_self";
  } else {
    target = "_blank";
  }

  useEffect(() => {
    (async () => {
      if (JSON.stringify(ctaWidgetGoogleAnalyticsObject) === "{}") {
        return;
      }

      if (ctaWidgetGoogleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({
            cta_widget_button_clicked_value: JSON.stringify(
              ctaWidgetGoogleAnalyticsObject
            ),
          });

          window.dataLayer.push({ event: "cta_widget_button_clicked" });
        }
      }
    })();
  }, [ctaWidgetGoogleAnalyticsObject]);

  useEffect(() => {
    (async () => {
      if (JSON.stringify(sidebarLinkGoogleAnalyticsObject) === "{}") {
        return;
      }

      if (sidebarLinkGoogleAnalyticsObject != {}) {
        if (window.dataLayer) {
          window.dataLayer.push({
            sidebar_link_clicked_value: JSON.stringify(
              sidebarLinkGoogleAnalyticsObject
            ),
          });

          window.dataLayer.push({ event: "sidebar_link_clicked" });
        }
      }
    })();
  }, [sidebarLinkGoogleAnalyticsObject]);

  var anchorClicked = function (url) {
    if (onClickHandler) {
      onClickHandler();
    }

    if (logCtaWidgetGoogleAnalyticsEvent) {
      setCtaWidgetGoogleAnalyticsObject({
        Url: url,
      });
    }

    if (logSidebarLinkGoogleAnalyticsEvent) {
      setSidebarLinkGoogleAnalyticsObject({
        Url: url,
      });
    }
  };

  let result = children;

  //  braze content cards
  if (brazeRedirectUrl) {
    return (
      <Link
        className={customClassName ? customClassName : classes.link}
        to={`${AddTrailingSlashToUrl(brazeRedirectUrl)}`}
        onClick={() => {
          anchorClicked(
            `${brazeRedirectUrl}`
          );
        }}
      >
        {children}
      </Link>
    )
  }

  //on sanityPostCategory
  if (_type == "post") {
    try {
      result = queryParams ? (
        <Link
          className={customClassName ? customClassName : classes.link}
          to={`/learn/articles/${reference.slug.current}${queryParams}`}
          onClick={() => {
            anchorClicked(
              `/learn/articles/${reference.slug.current}${queryParams}`
            );
          }}
        >
          {" "}
          {children}
        </Link>
      ) : (
        <Link
          className={customClassName ? customClassName : classes.link}
            to={`/learn/articles/${AddTrailingSlashToUrl(reference.slug.current)}`}
          onClick={() => {
            anchorClicked(`/learn/articles/${AddTrailingSlashToUrl(reference.slug.current)}`);
          }}
        >
          {" "}
          {children}
        </Link>
      );
    } catch (e) {
      result = null;
    }
  }
  // on questions
  if (_type == "questionPost") {
    try {
      result = queryParams ? (
        <Link
          className={customClassName ? customClassName : classes.link}
          to={`/ask/${reference.slug.current}${queryParams}`}
          onClick={() => {
            anchorClicked(
              `/learn/articles/${reference.slug.current}${queryParams}`
            );
          }}
        >
          {children}
        </Link>
      ) : (
        <Link
          className={customClassName ? customClassName : classes.link}
            to={`/ask/${AddTrailingSlashToUrl(reference.slug.current)}`}
          onClick={() => {
            anchorClicked(`/learn/articles/${AddTrailingSlashToUrl(reference.slug.current)}`);
          }}
        >
          {children}
        </Link>
      );
    } catch (e) {
      result = null;
    }
  }

  //BELOW NEEDS TESTING

  // on landing pages or infomation pages
  if (_type === "landingPage" || _type === "information") {
    var subfolder = "";

    if (reference.subfolder != null) {
      subfolder = reference.subfolder.folder;
    }
    if (queryParams) {
      return (
        <Link
          className={customClassName ? customClassName : classes.link}
          to={`${subfolder}${reference.slug.current}${queryParams}`}
          onClick={() => {
            anchorClicked(`${subfolder}${reference.slug.current}${queryParams}`);
          }}
        >
          {children}
        </Link>
      );
    }
    if (_type === "information" && isMenuItem){
      return (
      <Link
      className={customClassName ? customClassName : classes.link}
          to={`${subfolder}${AddTrailingSlashToUrl(reference.slug.current)}`}
      onClick={() => {
        anchorClicked(`${subfolder}${reference.slug.current}`);
      }}
    >
      {children}
    </Link>
      )
    }
    return (

      <Link
        className={customClassName ? customClassName : classes.link}
        to={`${subfolder}${AddTrailingSlashToUrl(reference.slug.current)}`}
        onClick={() => {
          anchorClicked(`${subfolder}${reference.slug.current}`);
        }}
      >
        {children}
      </Link>
    );
  }

  //on sanityLinkInformation
  if (_type === "linkCategory" || _type === "fundFactsheetCategory") {
    if (queryParams) {
      return (
        <a
          target={target}
          className={customClassName ? customClassName : classes.link}
          href={`${reference.url}${queryParams}`}
          onClick={() => {
            anchorClicked(`${reference.url}${queryParams}`);
          }}
        >
          {children}
        </a>
      );
    }

    return (
      <a
        target={target}
        className={customClassName ? customClassName : classes.link}
        href={`${AddTrailingSlashToUrl(reference.url)}`}
        onClick={() => {
          anchorClicked(reference.url);
        }}
      >
        {children}
      </a>
    );
  }

  if (_type === "widgetCategory") {
    var cta = false;

    if (customClassName != null && customClassName.includes("cta")) {
      cta = true;
    }
    if (!cta) {
      result = (
        <Dialog
          buttonText={children}
          noButtonHover={true}
          linkType="link"
          ctaClass={cta}
        />
      );
    }
  }

  return result;
};

export default Container;
