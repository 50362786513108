import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    maxWidth: "1170px",
  },
  spacer: {
    [theme.breakpoints.up("md")]:{
      flex: 1,
    }
  },
}));

const Container = ({ children, customClassName, customBodyClass, noSpacer }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      alignItems="center"
      justify="space-between"
      className={customClassName ? customClassName : null}
    >
      {!noSpacer && <Grid className={classes.spacer} item xs={0} md={1}></Grid>}
      <Grid item xs={noSpacer ? 12 : 11} md={noSpacer ? 12 : 10} className={customBodyClass ? customBodyClass : classes.bodyContainer}>
        {children}
      </Grid>
      {!noSpacer && <Grid className={classes.spacer} item xs={0} md={1}></Grid>}
    </Grid>
  );
};

export default Container;
