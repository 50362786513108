import React, { useEffect } from "react";

const RecaptchaLoader = () => {
  useEffect(() => {
    // Checking if the script is already added
    const existingScript = document.getElementById("recaptchaScript");
    if (!existingScript) {
      //Adding te script when the component is mounted
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.id = "recaptchaScript";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
    return () => {
      // Removing the script when the component is unmounted
      if (existingScript) {
        existingScript.remove();
      }
    };
  }, []);

  return null;
};

export default RecaptchaLoader;
