// extracted by mini-css-extract-plugin
export var anchorContainer = "ctaWidget-module--anchorContainer--bJK7o";
export var campusAnchorContainer = "ctaWidget-module--campusAnchorContainer--LhTZE";
export var campusCtaWidgetContainer = "ctaWidget-module--campusCtaWidgetContainer--MmfdW";
export var campusPinkCta = "ctaWidget-module--campusPinkCta--2HHU7";
export var ctaWidgetContainer = "ctaWidget-module--ctaWidgetContainer--vpQMd";
export var leftSideTextContainer = "ctaWidget-module--leftSideTextContainer--Bizqr";
export var leftSideTextContainerVisible = "ctaWidget-module--leftSideTextContainerVisible--rUdRB";
export var leftStripe = "ctaWidget-module--leftStripe--Iafqu";
export var leftStripeOrange = "ctaWidget-module--leftStripeOrange--ViyB2";
export var leftTextPara = "ctaWidget-module--leftTextPara--Xsgkj";
export var mobileFullWidth = "ctaWidget-module--mobileFullWidth--nJVla";
export var orangeCta = "ctaWidget-module--orangeCta--yS7Am";
export var pinkCta = "ctaWidget-module--pinkCta--I64tO";