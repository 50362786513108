import { format, isFuture } from "date-fns";
import sanityClient from "@sanity/client";
import { imageUrlFor } from "./image-url";

var CryptoJS = require("crypto-js");

var likedQuestionsCookieName = '';
var likedQuestionsCookieDelimiter = ',';

export function cn(...args) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(new Date(publishedAt));
}

export function getBlogUrl(publishedAt, slug) {
  return `/learn/articles/${slug.current || slug
    }/`;
}

export function getPgUrl(publishedAt, slug, loc) {

  return `/${loc}/${slug.current || slug
    }/`;
}

export function getPgUrlAlt(publishedAt, slug, loc) {

  return `${loc}${slug.current || slug
    }/`;
}

export function buildImageObj(source = { asset: {} }) {




  if ((source == null) || (source.asset == undefined)) {

    return null;
  }
  else {


    const imageObj = {
      asset: { _ref: source.asset._ref || source.asset._id },
    };

    if (source.crop) imageObj.crop = source.crop;
    if (source.hotspot) imageObj.hotspot = source.hotspot;

    return imageObj;
  }


}

export function toPlainText(blocks) {
  if (!blocks) {
    return "";
  }
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("\n\n");
}

export function signupToBlog(email) {

  var apiURL = process.env.GATSBY_SIGNUPAPI;
  var BMTracking = getCookie("BMTUi");
  var data = "{Email: \"" + email + "\", Referrer: \"Sanity CMS\", RespondentId:\"" + BMTracking + "\"}";

  var xhr = new XMLHttpRequest();

  xhr.addEventListener("readystatechange", function () {
    if (this.readyState === 4) {
      console.log("SIGNUP");
    }
  });

  xhr.open("POST", apiURL);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(data);

}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

var createCookie = function (name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
  } else {
    var expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

export function setCookie(name, value, days) {
  createCookie(name, value, days);
}

var eraseCookie = function (name) {
  createCookie(name, "", -1);
}



// checks string is null or empty
export function isNullOrEmptyString(str) {
  if (str == null || typeof str === 'undefined' || typeof str !== 'string') {
    return true;
  }

  if (str.length === 0)
    return true;

  return false;
}

export function checkQuestionAlreadyLiked(questionId) {
  if (isNullOrEmptyString(questionId)) {
    return false;
  }

  var cookie = getCookie('liked-questions');

  if (isNullOrEmptyString(cookie)) {
    return false;
  }

  var questionIds = cookie.split(likedQuestionsCookieDelimiter);

  var questionAlreadyLiked = false;

  questionIds.forEach(function (id) {
    if (id == questionId) {
      questionAlreadyLiked = true;

      return false;
    }
  });

  return questionAlreadyLiked;
}

export function appendOrCreateQuestionAnsweredCookie(name, value, days) {
  var cookie = getCookie(name);

  if (isNullOrEmptyString(cookie)) {
    createCookie(name, value, days);

    return false;
  }

  var appendedValue = cookie + likedQuestionsCookieDelimiter + value;

  eraseCookie(name);

  createCookie(name, appendedValue, days);
}


export function publishDate(date) {
  //date: YYYY-MM-DDTHH:mm:ss:sssZ
  const monthMap = {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec'
  }
  var dateObj = new Date(date);
  var day = dateObj.getUTCDate();
  var month = monthMap[dateObj.getMonth()];
  var year = dateObj.getFullYear();

  var newdate = day + " " + month + ", " + year;
  return newdate;
}

export function createClient() {

  var useGroqCDN = true;
  if (process.env.NODE_ENV == "development") {
    useGroqCDN = false;
  }

  const client = sanityClient(
    {
      projectId: process.env.GATSBY_SANITY_PROJECT_ID,
      dataset: process.env.GATSBY_SANITY_DATASET,
      apiVersion: 'v1',
      useCdn: useGroqCDN,
    }
  );

  return client;
}

//export function getSf(query) {

//  const client = createClient();

//  var result = client.fetch(query)
//      .then((response) => { console.log(response)})
//      .then((value) => { console.log(value) });

//  Promise.resolve(result).then(function (value) {
//    return value;
//  })

//}


export async function getNumberOfLikes(adviserId) {
  return fetch(process.env.GATSBY_QUESTIONAPI + '/NumberOfAdviserLikes/Post', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(adviserId)
  })
    .then(response => {
      return response.json()
    })
    .then(data => { return data.NumberOfLikes; })
    .catch(error => console.log('error', error));
}

export async function getAdviserQuestions(dashboardId) {
  var model = {
    Categories: '',
    AgeBrackets: '',
    OrderBy: 'Likes',
    Offset: 0,
    ItemsPerPage: 5,
    AdviserId: dashboardId
  }

  return fetch(process.env.GATSBY_QUESTIONAPI + '/AdviserFilteredQuestions/FilteredQuestions', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(model)
  })
    .then((response) => response.json())
    .then((responseJson) => { return responseJson });
}

export function truncateAString(str, n) {
  return (str.length > n) ? str.substr(0, n - 1) + '&hellip;' : str;
};

export function getSortOptionLabelById(id) {
  if (id == null || id === undefined || id.length === 0 || id === '') {
    return '';
  }

  var lowercaseId = id.toLowerCase();

  switch (lowercaseId) {
    case 'ourratinghl':
      return 'Our Rating (Descending)';
    case 'ourratinglh':
      return 'Our Rating (Ascending)';
    case 'yourratinghl':
      return 'Your Rating (Descending)';
    case 'yourratinglh':
      return 'Your Rating (Ascending)';
    case 'costhl':
      return 'Cost (Descending)';
    case 'costlh':
      return 'Cost (Ascending)';
    case 'mostpopular':
      return 'Most Popular';
    case 'mostrecommended':
      return 'Most Recommended';
  }
}

export function ValidateEmail(email) {
  if (!email || typeof (email) === 'undefined') {
    return false;
  }

  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function GetDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
  var R = 3958; // Radius of the earth in miles
  var dLat = deg2rad(lat2 - lat1);  // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in miles
  return d;
}

var deg2rad = function (deg) {
  return deg * (Math.PI / 180)
}

export function AddTrailingSlashToSlug(slug) {
  if (!slug || !typeof slug === 'string' || slug.length === 0) {
    return slug;
  }

  if (slug[slug.length - 1] !== '/') {
    return slug + '/';
  }
}

export function AddTrailingSlashToUrl(url) {

  if (url) {
    if (url.includes("pdf")) {

      return url;
    }

    if (!url || !typeof url === 'string' || url.length === 0 || url === '') {
      return url;
    }

    //skip mailto
    if (url.indexOf('mailto:') === 0) {
      return url;
    }

    //skip PDFs
    if (url.indexOf('.pdf') === 0) {
      return url;
    }

    //skip bitly
    if (url.includes('bit.ly')) {
      return url;
    }

    //skip external
    if (url.includes('www.')) {
      return url;
    }
    if (url.includes('http')) {
      return url;
    }

    //skip url with query string
    if (url.indexOf('?') !== -1) {
      return url;
    }

    if (url[url.length - 1] !== '/') {
      //console.log('return + /');
      return url + '/';
    }

  }


  return url;
}

// this method uses window so it's meant to be used inside useEffect

export async function getUserObject(token) {
  var userObject = {
    IsAuthenticated: false,
    ServerError: false,
    ServerErrorMessage: null,
    Id: null,
    Username: null,
    Email: null,
    ForumUserId: null,
    EmailConfirmed: false,
    BrazeId: null
  }

  if (!token || !typeof token === 'string' || token.length === 0 || token === '') {
    return userObject;
  }

  var userIdResponse = await fetch(process.env.GATSBY_QUESTIONAPI + '/UserDetails/GetUserDetails', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Accept': '*/*',
      'Content-Type': 'application/json'
    }
  })
    .then((response) => response)
    .then((responseJson) => { return responseJson });

  if (userIdResponse.redirected) {
    // not authenticated
    return userObject;
  } else {
    var json = await userIdResponse.json();

    userObject.IsAuthenticated = true;

    if (json.Success) {
      userObject.Id = json.Result.UserId;
      userObject.ForumUserId = json.Result.ForumUserId;
      userObject.Email = json.Result.Email;
      userObject.Username = json.Result.UserName;
      userObject.EmailConfirmed = json.Result.EmailConfirmed;
      userObject.BrazeId = json.Result.BrazeId;

      window.localStorage.setItem("UserId", userObject.Id);
      window.localStorage.setItem("ForumUserId", userObject.ForumUserId);
      window.localStorage.setItem("Email", userObject.Email);
      window.localStorage.setItem("Username", userObject.Username);
      window.localStorage.setItem("EmailConfirmed", userObject.EmailConfirmed);
      window.localStorage.setItem("BrazeId", userObject.BrazeId);
    }
    else {
      // server error
      userObject.ServerError = true;
      userObject.ServerErrorMessage = json.ErrorMessage;
    }
  }

  return userObject;
}

export function getPageUrlOutOfSanityReference(pageReference) {
  if (!pageReference) {
    return '';
  }

  if (!pageReference._type) {
    return '';
  }

  if (pageReference._type == "post") {
    return '/learn/articles/' + pageReference.slug.current;
  }

  if (pageReference._type == "questionPost") {
    return '/ask/' + pageReference.slug.current;
  }

  if (pageReference._type === "landingPage" || pageReference._type === "information") {
    var subfolder = "";

    if (pageReference.subfolder != null) {
      subfolder = pageReference.subfolder.folder;
    }

    return subfolder + pageReference.slug.current;
  }

  if (pageReference._type === "fundInfo"){
    return '/funds/' + pageReference.slug.current;
  }
  if (pageReference._type === "course"){

    var subfolder = "";

    if (pageReference.subfolder != null) {
      subfolder = pageReference.subfolder.folder;
    }

    return subfolder + pageReference.slug.current;
  }
}

export async function checkIsCampusTheme(isTextbox) {
  var path = window.location.pathname;

  if (path && path !== '/') {
    if (path[path.length - 1] === '/') {
      path = path.slice(0, path.length - 1);
    }
  }

  var split = path.split('/');

  var lastSegment = isTextbox ? decodeURIComponent(split[split.length - 1]) : split[split.length - 1];

  const params = {
    pageType: 'information',
  };

  var query = '*[_type == $pageType && defined(publishedAt) && slug.current == "' + lastSegment + '"] { theme }';

  var client = createClient();

  var result = await client.fetch(query, params)
    .then((response) => response)
    .then((responseJson) => { return responseJson });

  if (!result || result.length === 0 || !result[0].theme || result[0].theme !== 'Boring Money Campus theme') {
    return false;
  }

  return true;
}

export async function checkIsLesson() {
  var path = window.location.pathname;

  if (path && path !== '/') {
    if (path[path.length - 1] === '/') {
      path = path.slice(0, path.length - 1);
    }
  }

  var split = path.split('/');

  var lastSegment = split[split.length - 1];

  const params = {
    pageType: 'course',
  };

  var query = '*[_type == $pageType && defined(publishedAt) && slug.current == "' + lastSegment + '"]';

  var client = createClient();

  var result = await client.fetch(query, params)
    .then((response) => response)
    .then((responseJson) => {
      return responseJson
    });

  //console.log("lesson result", result)
  if (!result || result.length === 0) {
    return false;
  }
  return result;
}

// get anchor tags for page
export async function getPageAnchor() {
  var path = window.location.pathname;

  if (path && path !== '/') {
    if (path[path.length - 1] === '/') {
      path = path.slice(0, path.length - 1);
    }
  }

  var split = path.split('/');

  var lastSegment = split[split.length - 1];

  var firstSegment = "/" + split[1];

  if (split[1] != "") {
    firstSegment += "/"
  }

  //console.log("getPageAnchor", firstSegment)

  var query = '*[defined(publishedAt) && slug.current == "' + lastSegment + '" && subfolder->folder == "' + firstSegment + '"]{linkMenu[]->{..., menuLinksTop[]{..., reference->, menuChildLinks[]{..., reference->}}}}';

  //console.log("query", query)

  var client = createClient();

  var result = await client.fetch(query)
    .then((response) => response)
    .then((responseJson) => { return responseJson });

  if (!result || result.length === 0) {
    return false;
  }

  return result;
}


//forum votes

export async function upDownVote(pID) {

  var cookie = getCookie("PostVotes");

  if (cookie == "") {

    //hasn't voted yet
    var cookieValue = pID + "-UP|";
    setCookie("PostVotes", cookieValue, 3650)

    return cookieValue;
  }
  else {
    var cArr = cookie.split("|");

    var postUp = pID + "-UP";
    var postDown = pID + "-DOWN";
    var index = cArr.indexOf(postUp);

    eraseCookie("PostVotes");

    if (index !== -1) {
      cArr[index] = postDown;
      createCookie("PostVotes", cArr.join("|"), 3650);

      return cArr.join("|");
    }

    index = cArr.indexOf(postDown);

    if (index !== -1) {
      cArr[index] = postUp;
      createCookie("PostVotes", cArr.join("|"), 3650);
      return cArr.join("|");
    }
    else {
      var cookieValue = pID + "-UP";
      cArr.push(cookieValue)
      createCookie("PostVotes", cArr.join("|"), 3650);
      return cArr.join("|");
    }


  }
}


//forum votes
export function hasVotedOnPost(pID) {

  var cookie = getCookie("PostVotes");

  if (cookie == "") {
    return false;
  }
  else {
    var cArr = cookie.split("|");

    var postUp = pID + "-UP";

    var index = cArr.indexOf(postUp);

    if (index !== -1) {
      return true;
    }
    else {
      return false;
    }

  }

}

export async function voteForumPost(pID, uID, delta) {

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + process.env.NODEBB_MASTER_API_TOKEN);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "delta": delta
  });

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  var url = process.env.GATSBY_NODEBB_URL + "v3/posts/" + pID + "/vote?_uid=" + uID;

  return fetch(url, requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
}

export async function GetForumPost(pID) {

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + process.env.NODEBB_MASTER_API_TOKEN);
  myHeaders.append("Content-Type", "application/json");

  var raw = "";

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  var url = process.env.GATSBY_NODEBB_URL + "v3/posts/" + pID + "?_uid=" + 1;

  return fetch(url, requestOptions)
    .then(response => response.json())
    .then(result => { console.log(result);})
    .catch(error => console.log('error', error));
}


//forum - report posts
export function hasReportedPost(pID) {

  var cookie = getCookie("PostReported");

  if (cookie == "") {
    return false;
  }
  else {
    var cArr = cookie.split("|");

    var isThere = (element) => element == pID;

    if (cArr.findIndex(isThere) !== -1) {
      return true;
    }
    else {
      return false;
    }

  }

}
export async function reportPost(pID) {

  var cookie = getCookie("PostReported");

  if (cookie == "") {

    //Nothing reported yet
    var cookieValue = pID + "|";
    setCookie("PostReported", cookieValue, 3650)

    return cookieValue;
  }
  else {
    var cArr = cookie.split("|");

    var index = cArr.indexOf(pID);

    eraseCookie("PostReported");

    if (index !== -1) {
      cArr[index] = pID;
      createCookie("PostReported", cArr.join("|"), 3650);
      return cArr.join("|");
    }
    else {
      var cookieValue = pID;
      cArr.push(cookieValue)
      createCookie("PostReported", cArr.join("|"), 3650);
      return cArr.join("|");
    }


  }
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function decodeEmail(encodedEmail) {
  if (!encodedEmail) {
    return '';
  }

  // encodedEmail is a comma sepparated string of ASCII characters
  // so we need to split by comma
  var split = encodedEmail.split(',');

  if (!split || split.length === 0) {
    return '';
  }

  var decodedEmail = '';

  for (var i = 0; i < split.length - 1; i++) {
    decodedEmail = decodedEmail + String.fromCharCode(split[i]);
  }

  return decodedEmail;
}

export function replacePlaceholders(inputObj, placeholder, replacement) {

  const newObj = JSON.parse(JSON.stringify(inputObj));

  function replaceInObject(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        obj[key] = obj[key].replace(placeholder, replacement);
      } else if (typeof obj[key] === "object") {
        replaceInObject(obj[key]);
      }
    }
  }

  replaceInObject(newObj);
  return newObj;
}


export async function submitMultistageForm(data,email,page, age, gender)
{

  console.log("submitMultistageForm-1", page)

  const apiUrl = process.env.GATSBY_QUESTIONAPI;

  const model = {
    email: email,
    FirstName: data.firstName,
    LastName: data.lastName,
    Age: age,
    Gender: gender,
    InvestmentConf: data.Registration_Investing_Confidence,
    RetirementStatus: data.Registration_Retirement_Status,
    FormPage: page //or 2 etc...
  }

  console.log('model', model)

  var encodedModel = JSON.stringify(model);

  var key = CryptoJS.enc.Utf8.parse('8056483646328763');
  var iv = CryptoJS.enc.Utf8.parse('8056483646328763');

  var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(encodedModel), key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();

  const encModel = {
    Token: encrypted
  }

  console.log("submitMultistageForm-2", encModel)

  const result = await fetch(apiUrl + "/AddUserDetails", {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(encModel),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      localStorage.setItem("profile", responseJson)

      return responseJson;
    }).catch(err => {
      console.log('AddUserDetails:', err);
    });

  console.log("submitMultistageForm-3", result)

  return result;

}

export function emailCaptureSubmissionLogging(url = window.location.href){


//   Braze
  window.brazeEmailSubmitLog(url)

//   Google
  if (window.dataLayer) {
    window.dataLayer.push({
      email_submitted_url_value: JSON.stringify(url)
    })

    window.dataLayer.push({ event: "email_submitted" });
  }
}

export async function providerCalculator(jsonModel) {

  const apiUrl = process.env.GATSBY_CALC_COST_API;

  const result = await fetch(apiUrl, {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
    body: jsonModel,
  })
    .then((response) => response.json())
    .then((responseJson) => {
        return responseJson;
    }).catch(err => {
      console.log('ProviderCalculator:', err);
    });

   return result;
}

export function providerFeeCalculatorLog(
  providerName,
  dashboardId,
  investmentWrapper,
  investmentAmount
) {

  // Google
  if (window.dataLayer) {
    let model = {
      ProviderName: providerName,
      DashboardID: dashboardId,
      InvestmentWrapper: investmentWrapper,
      investmentAmount: investmentAmount,
    }

    window.dataLayer.push({
      provider_fee_calculator_value: JSON.stringify(model),
    })

    window.dataLayer.push({
      event: "provider_fee_calculator_submitted"
    })
  }

  //   Braze
  window.brazeProviderFeeCalculator(
    providerName,
    dashboardId,
    investmentWrapper,
    investmentAmount
  )


}
