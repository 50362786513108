import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import ButtonIcon from "../icon/ArrowRightSquare.svg";
import {
  Portal,
  Grid,
  ClickAwayListener,
  Button,
  Typography,
} from "@material-ui/core";
import ContactForm from "./askQuestionForm";

import * as styles from "../ctaWidget/ctaWidget.module.css";
import { set } from "date-fns";

import { toggleBlur, toggleOff } from "../../redux/reducers/blur";


const useStyles = makeStyles((theme) => ({
  dropdown: {
    position: "fixed",
    width: 400,
    height: 450,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    zIndex: 99999,
    borderRadius: "8px",
    padding: "24px",
    border: "1px solid #DBE3EF",
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    [theme.breakpoints.down("xs")]: {
      width: 335,
      height: 469,
      padding: "16px",
    },
    [theme.breakpoints.down(345)]: {
      width: 300,
      height: 469,
      padding: "16px",
    },
  },
  backdrop: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 99999,
  },
  contactMeButton: {
    borderRadius: "8px",
    background: "#FF0F5F",
    padding: "1em 2em",
    color: '#ffffff',
    /* Drop shadow Mas */
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    height: "56px",
    "&:hover": {
      background: "#fff",
    },
    "&:hover $contactMeButtonText": {
      color: "#FF0F5F",
    },
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
      marginTop: "28px",
    },
  },
  contactMeButtonNoHover: {
    borderRadius: "8px",
    background: "#FF0F5F",
    padding: "1em 2em",
    /* Drop shadow Mas */
    boxShadow: "0px 2px 4px rgba(64, 64, 64, 0.1)",
    height: "56px",
    "&:hover": {
      background: "#FF0F5F",
    },
    [theme.breakpoints.down("sm")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
    },
  },
  contactMeButtonText: {
    // color: "#ffff",
  },
  contactMeButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contactMeButtonContainerLink: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: 'block'
    },
    display:"table-cell",
  },
  contactLink: {
    cursor:"pointer"
  }
}));
function ContactMeButton({ buttonText, noButtonHover, linkType, ctaClass, ctaClassName, questionOpen, setQuestionOpen, isBlur, dispatch}) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!open){
      dispatch(toggleOff());
    }
    (async () => {
      if (open) {
        if (window.dataLayer) {
          window.dataLayer.push({ 'question_popup_open_value': window.location.href });

          window.dataLayer.push({ event: 'question_popup_open' });
        }
      }
    })();
  }, [open]);

  const handleClick = () => {
    setOpen((prev) => !prev);
    dispatch(toggleBlur(!isBlur))
  };

  const handleClickAway = () => {
    setOpen(false);
    // if (!open){
    // dispatch(toggleBlur(!isBlur))
    // }
  };

  if (linkType == "link") {

    if (ctaClass) {
      return (
          <div>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <span className={styles.pinkCta} onClick={handleClick}>
                  <Typography
                    variant="button"
                    className={classes.contactMeButtonText}
                  >
                    {buttonText}
                  </Typography>
                </span>
                {open ? (
                  <Portal>
                  <Grid className={`${classes.dropdown} ${'ask-question-popup'}`}>
                      <ContactForm onClickingAwayHandler={handleClickAway} />
                    </Grid>
                  </Portal>
                ) : null}
              </div>
            </ClickAwayListener>
          </div>
      );
    }
    else {
      return (
        <div className={classes.contactMeButtonContainerLink}>
          {open && <div className={classes.backdrop} />}
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <span className={classes.contactLink} onClick={handleClick}>
                <Typography
                  variant="button"
                  className={classes.contactMeButtonText}
                >
                  {buttonText}
                </Typography>
              </span>
              {open ? (
                <Portal>
                  <Grid className={`${classes.dropdown} ${'ask-question-popup'}`}>
                    <ContactForm onClickingAwayHandler={handleClickAway} />
                  </Grid>
                </Portal>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>);
    }


  }
  else {
    return (
      <div className={classes.contactMeButtonContainer}>
        {open && <div className={classes.backdrop} />}
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <Button className={noButtonHover ? classes.contactMeButtonNoHover : classes.contactMeButton} onClick={handleClick}>
              <Typography
                variant="button"
                className={classes.contactMeButtonText}
              >
                {buttonText}
              </Typography>
            </Button>
            {open ? (
              <Portal>
                <Grid className={`${classes.dropdown} ${'ask-question-popup'}`}>
                  <ContactForm onClickingAwayHandler={handleClickAway} />
                </Grid>
              </Portal>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    );
  }



}

export default connect(state => ({
  isBlur: state.blurReducer.isBlur
}), null)(ContactMeButton);
