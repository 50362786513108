import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import AdvertWidget from "./advertBottomWidget";

const useStyles = makeStyles((theme) => ({
  backgroundDrop: {
    backgroundColor: "#f5f7f8",
    display: "block",
  },
  backgroundDropCourse: {
    background: "#FFFFFF",
  },
  backgroundDropCourseDropdownActive: {
    backgroundColor: "rgba(60, 70, 95, 0.8)",
  },
  backgroundDropBlur: {
    background:
      "linear-gradient(90deg, rgba(96, 108, 136, 0.45) 0%, rgba(63, 76, 107, 0.45) 100%) !important",
    filter: "blur(5px)",
    "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)": {

      [theme.breakpoints.down("md")]: {
        backgroundSize: "100% ",
      },
    },
  },
  backgroundDropFilter: {
    backdropFilter: "blur(100px)",
    display: "block",
  },
  bodyContainer: {
    maxWidth: "1170px",
  },
}));

const ContainerForPages = ({ children, isBlur, isCoursePage, dropdownOpen }) => {
  const classes = useStyles();

  const getBackgroundDropClass = () => isCoursePage ? (dropdownOpen ? classes.backgroundDropCourseDropdownActive : classes.backgroundDropCourse) : (dropdownOpen ? classes.backgroundDropCourseDropdownActive : classes.backgroundDrop);
  return (
    <Grid
      container
      item
      alignItems="center"
      justify="center"
      className={isBlur ? classes.backgroundDropBlur : getBackgroundDropClass()}
    >
      <Grid
        container
        item
        alignItems="center"
        justify="center"
        direction="column"

        className={isCoursePage ? null : classes.backgroundDropFilter}
      >

        {children}
      </Grid>


    </Grid>
  );
};

export default connect(state => ({
  isBlur: state.blurReducer.isBlur
}), null)(ContainerForPages)
