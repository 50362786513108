import { useForm, Controller } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RecaptchaLoader from "../../lib/RecaptchaLoader";

import {
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
  Grid,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import DangerTriangle from "../icon/DangerTriangle.svg";
import CloseSquare from "../icon/CloseSquare.svg";
import ArrowRightSquare from "../icon/ArrowRightSquare.svg";
import ArrowLeft from "../icon/ArrowLeft.svg";
import Step3MessageSent from "./components/step3MessageSent";
import Send from "../icon/Send.svg";
import { createClient } from "../../lib/helpers";
import Recaptcha from "react-recaptcha";
import { useStaticQuery, graphql } from "gatsby";
import { toggleMenuItemHoverOff } from "../../redux/reducers/menuItemHover";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#2F2F4E",
    maxWidth: "312px",
    paddingLeft: "4px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "263px",
    },
  },
  sendAMessage: {
    color: "#3C455C",
  },
  collapsePopupButton: {
    minWidth: "24px !important",
    width: 24,
    height: 24,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
  popupHeader: {
    marginBottom: "20px",
  },
  yourQuestionText: {
    marginBottom: "16px",
    "& textarea": {
      padding: "17px 24px",
      lineHeight: "24px",
    },
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  disclaimerContainer: {
    padding: "8px",
    borderRadius: "8px",
    border: "1px solid #DBE3EF",
    marginBottom: "16px",
  },
  nextButton: {
    backgroundColor: "#DBE3EF !important",
    borderRadius: "8px",
    width: "123px",
    height: "56px",
    position: "absolute",
    bottom: "0",
    right: "0",
    [theme.breakpoints.down("xs")]: {
      // maxWidth: "150px",
      // maxHeight: "150px",
      width: "100%",
    },
    "& img": {
      marginLeft: ".4em",
    },
  },
  basicFieldName: {
    marginBottom: "16px",
    width: "96%",
    "& input": {},
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  basicFieldNameFullWidth: {
    marginBottom: "16px",
    width: "100%",
    "& input": {},
    "& div": {
      padding: 0,
      borderRadius: "8px",
      boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
      backgroundColor: "#F5F7FA",
    },
    "& fieldset": {
      border: "none",
    },
  },
  select: {
    width: "100%",
    borderRadius: "8px",
    padding: "16px, 24px, 16px, 24px",
    "& .MuiPaper-root": {
      left: "24px !important",
      top: "280px !important",
      [theme.breakpoints.down("xs")]: {
        left: "14px !important",
      },
    },
    "& .MuiInputBase-root": {},
  },
  captchaDisclaimer: {
    fontSize: "10px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0px",
    color: "#8C8CA1",
    marginTop: "27px",
    marginBottom: "27px",
  },
  popupSubmitButton: {
    backgroundColor: "#FF0F5F",
    padding: "16px 24px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    "&:hover": {
      backgroundColor: "#FF0F5F",
    },
    borderRadius: "8px",
    position: "absolute",
    bottom: "0",
    right: "0",
  },
  popupSubmitButtonText: {
    color: "#fff",
    paddingRight: "8px",
  },
  popupBackButton: {
    padding: "1em",
    background: "#FFFFFF",
    filter: "drop-shadow(0px 2px 4px rgba(64, 64, 64, 0.1))",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 11px",
    },
    borderRadius: "8px",
    position: "absolute",
    bottom: "0",
    left: "0",
  },
  popupBackButtonText: {
    paddingLeft: "8px",
  },
  errorLabel: {
    color: "#FF0F5F",
    display: "block",
  },
  error: {
    border: "1px solid #FF0F5F",
    borderRadius: "8px",
    marginBottom: "0",
  },
  form: {
    height: "100%",
    position: "relative",
  },
  recap: {
    display: "none",
  },
}));

let recaptchaInstance;
const executeCaptcha = (e) => {
  e.preventDefault();
  recaptchaInstance.reset();

  recaptchaInstance.execute();
};

const GetDictionary = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityDictionary(
        filter: {
          key: {
            in: [
              "ask_question_popup_first_step_header_text"
              "ask_question_popup_second_step_header_text"
              "ask_question_popup_question_text_placeholder"
              "ask_question_popup_question_required_error_message"
              "ask_question_popup_disclaimer"
              "ask_question_popup_next_button_text"
              "ask_question_popup_first_name_placeholder"
              "ask_question_popup_first_name_required_error_message"
              "ask_question_popup_first_name_required_error_message"
              "ask_question_popup_last_name_placeholder"
              "ask_question_popup_last_name_placeholder"
              "ask_question_popup_last_name_required_error_message"
              "ask_question_popup_email_placeholder"
              "ask_question_popup_email_required_error_message"
              "ask_question_popup_email_invalid_error_message"
              "ask_question_popup_select_age_label"
              "ask_question_popup_captcha_disclaimer"
              "ask_question_popup_back_button_text"
              "ask_question_popup_submit_button_text"
              "ask_question_popup_thankyou_message"
              "ask_question_popup_thankyou_message_body"
              "ask_question_popup_close_button_text"
            ]
          }
        }
      ) {
        nodes {
          value
          key
        }
      }
    }
  `);

  return data.allSanityDictionary.nodes;
};

function ContactForm(props) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm();
  const { dispatch } = props;

  var apiURL = process.env.GATSBY_QUESTIONAPI;
  const classes = useStyles();
  function handleOnClickingAwayHandler() {
    dispatch(toggleMenuItemHoverOff());

    props.onClickingAwayHandler();
  }

  const [firstStepHeaderText, setFirstStepHeaderText] = useState("");
  const [secondStepHeaderText, setSecondStepHeaderText] = useState("");
  const [questionTextPlaceholder, setQuestionTextPlaceholder] = useState("");
  const [questionRequiredErrorMessage, setQuestionRequiredErrorMessage] =
    useState("");
  const [disclaimer, setDisclaimer] = useState("");
  const [nextButtonText, setNextButtonText] = useState("");
  const [firstNamePlaceholder, setFirstNamePlaceholder] = useState("");
  const [firstNameRequiredErrorMessage, setFirstNameRequiredErrorMessage] =
    useState("");
  const [lastNamePlaceholder, setLastNamePlaceholder] = useState("");
  const [lastNameRequiredErrorMessage, setLastNameRequiredErrorMessage] =
    useState("");
  const [emailPlaceholder, setEmailPlaceholder] = useState("");
  const [emailRequiredErrorMessage, setEmailRequiredErrorMessage] =
    useState("");
  const [emailInvalidErrorMessage, setEmailInvalidErrorMessage] = useState("");
  const [selectAgeLabel, setSelectAgeLabel] = useState("");
  const [captchaDisclaimer, setCaptchaDisclaimer] = useState("");
  const [backButtonText, setBackButtonText] = useState("");
  const [submitButtonText, setSubmitButtonText] = useState("");
  const [thankYouMessage, setThankYouMessage] = useState("");
  const [thankYouMessageBody, setThankYouMessageBody] = useState("");
  const [closeButtonText, setCloseButtonText] = useState("");
  const [questionSubmitted, setQuestionSubmitted] = useState(false);
  const [dictionary, setDictionary] = useState(GetDictionary());

  useEffect(() => {
    (async () => {
      setFirstStepHeaderText(
        dictionary.find(
          (x) => x.key === "ask_question_popup_first_step_header_text"
        ).value
      );
      setSecondStepHeaderText(
        dictionary.find(
          (x) => x.key === "ask_question_popup_second_step_header_text"
        ).value
      );
      setQuestionTextPlaceholder(
        dictionary.find(
          (x) => x.key === "ask_question_popup_question_text_placeholder"
        ).value
      );
      setQuestionRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "ask_question_popup_question_required_error_message"
        ).value
      );
      setDisclaimer(
        dictionary.find((x) => x.key === "ask_question_popup_disclaimer").value
      );
      setNextButtonText(
        dictionary.find((x) => x.key === "ask_question_popup_next_button_text")
          .value
      );
      setFirstNamePlaceholder(
        dictionary.find(
          (x) => x.key === "ask_question_popup_first_name_placeholder"
        ).value
      );
      setFirstNameRequiredErrorMessage(
        dictionary.find(
          (x) =>
            x.key === "ask_question_popup_first_name_required_error_message"
        ).value
      );
      setLastNamePlaceholder(
        dictionary.find(
          (x) =>
            x.key === "ask_question_popup_first_name_required_error_message"
        ).value
      );
      setLastNamePlaceholder(
        dictionary.find(
          (x) => x.key === "ask_question_popup_last_name_placeholder"
        ).value
      );
      setLastNameRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "ask_question_popup_last_name_placeholder"
        ).value
      );
      setLastNameRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "ask_question_popup_last_name_required_error_message"
        ).value
      );
      setEmailPlaceholder(
        dictionary.find((x) => x.key === "ask_question_popup_email_placeholder")
          .value
      );
      setEmailRequiredErrorMessage(
        dictionary.find(
          (x) => x.key === "ask_question_popup_email_required_error_message"
        ).value
      );
      setEmailInvalidErrorMessage(
        dictionary.find(
          (x) => x.key === "ask_question_popup_email_invalid_error_message"
        ).value
      );
      setSelectAgeLabel(
        dictionary.find((x) => x.key === "ask_question_popup_select_age_label")
          .value
      );
      setCaptchaDisclaimer(
        dictionary.find(
          (x) => x.key === "ask_question_popup_captcha_disclaimer"
        ).value
      );
      setBackButtonText(
        dictionary.find((x) => x.key === "ask_question_popup_back_button_text")
          .value
      );
      setSubmitButtonText(
        dictionary.find(
          (x) => x.key === "ask_question_popup_submit_button_text"
        ).value
      );
      setThankYouMessage(
        dictionary.find((x) => x.key === "ask_question_popup_thankyou_message")
          .value
      );
      setThankYouMessageBody(
        dictionary.find(
          (x) => x.key === "ask_question_popup_thankyou_message_body"
        ).value
      );
      setCloseButtonText(
        dictionary.find((x) => x.key === "ask_question_popup_close_button_text")
          .value
      );
    })();
  }, [dictionary]);

  useEffect(() => {
    (async () => {
      if (questionSubmitted) {
        if (window.dataLayer) {
          window.dataLayer.push({
            question_submitted_value: window.location.href,
          });

          window.dataLayer.push({ event: "question_submitted" });
        }

        var date = "";

        var model = {
          Location: window.location.href,
          Date: date,
          question_submitted: true,
        };

        //braze
        try {
          window.trackQuestionSubmit(model);
        } catch (e) {}
      }
    })();
  }, [questionSubmitted]);

  function onSubmit(data) {
    fetch(apiURL + "/PostQuestion/PostQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
    setQuestionSubmitted(true);
    setStep(3);
  }
  const [step, setStep] = React.useState(1);
  // var apiURL = process.env.GATSBY_QUESTIONAPI;
  // console.log(apiURL)
  function handleNext(data) {
    data.QuestionText && setStep(2);
  }
  function handleBack(data) {
    setStep(1);
  }

  return (
    <>
      <form id="qform" className={classes.form}>
        <RecaptchaLoader />
        <Recaptcha
          ref={(e) => (recaptchaInstance = e)}
          sitekey="6LcWa-IbAAAAAAY8dsZcdQVnp8ycB-9iM0dbl6g8"
          size="invisible"
          verifyCallback={handleSubmit(onSubmit)}
          className={classes.recap}
        />
        {(step === 1 || step === 2) && (
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            className={classes.popupHeader}
          >
            <Typography variant="h4" className={classes.sendAMessage}>
              {step === 1 && firstStepHeaderText}{" "}
              {step === 2 && secondStepHeaderText}
            </Typography>

            <Button
              className={classes.collapsePopupButton}
              onClick={() => handleOnClickingAwayHandler()}
            >
              <img src={CloseSquare} alt="CloseSquare" />
            </Button>
          </Grid>
        )}
        {step === 1 && (
          <div>
            <Controller
              name="QuestionText"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={5}
                  placeholder={questionTextPlaceholder}
                  className={`${
                    errors.QuestionText?.type === "required"
                      ? classes.error
                      : ""
                  } ${classes.yourQuestionText}`}
                />
              )}
            />
            {errors.QuestionText?.type === "required" && (
              <span className={classes.errorLabel}>
                {questionRequiredErrorMessage}
              </span>
            )}

            <Grid
              item
              container
              alignItems="flex-start"
              className={classes.disclaimerContainer}
            >
              <Grid item>
                <img src={DangerTriangle} alt="DangerTriangle" />
              </Grid>
              <Grid item className={classes.disclaimer}>
                <Typography variant="body2" className={classes.disclaimer}>
                  {disclaimer}
                </Typography>
              </Grid>
            </Grid>
            <Button
              className={classes.nextButton}
              onClick={handleSubmit(handleNext)}
            >
              <Typography variant="button">{nextButtonText}</Typography>{" "}
              <img src={ArrowRightSquare} alt="ArrowRightSquare" />
            </Button>
          </div>
        )}
        {step === 2 && (
          <div>
            <Grid item container>
              <Grid item xs={6}>
                <Controller
                  name="FirstName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      placeholder={firstNamePlaceholder}
                      className={`${
                        errors.FirstName?.type === "required"
                          ? classes.error
                          : ""
                      } ${classes.basicFieldName}`}
                      fullWidth
                    />
                  )}
                />
                {errors.FirstName?.type === "required" && (
                  <span className={classes.errorLabel}>
                    {firstNameRequiredErrorMessage}
                  </span>
                )}
              </Grid>
              <Grid item xs={6} container justify="flex-end">
                <Controller
                  name="LastName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      placeholder={lastNamePlaceholder}
                      className={`${
                        errors.LastName?.type === "required"
                          ? classes.error
                          : ""
                      } ${classes.basicFieldName}`}
                      fullWidth
                    />
                  )}
                />
                {errors.LastName?.type === "required" && (
                  <span className={classes.errorLabel}>
                    {lastNameRequiredErrorMessage}
                  </span>
                )}
              </Grid>
            </Grid>
            <Controller
              name="Email"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  placeholder={emailPlaceholder}
                  className={`${
                    errors.Email?.type === "required" ||
                    errors.Email?.type === "pattern"
                      ? classes.error
                      : ""
                  } ${classes.basicFieldNameFullWidth}`}
                  fullWidth
                />
              )}
            />
            {(errors.Email?.type === "required" && (
              <span className={classes.errorLabel}>
                {emailRequiredErrorMessage}
              </span>
            )) ||
              (errors.Email?.type === "pattern" && (
                <span className={classes.errorLabel}>
                  {emailInvalidErrorMessage}
                </span>
              ))}
            <Controller
              {...register("AgeBracketId")}
              control={control}
              render={({ field }) => (
                <FormControl className={classes.select}>
                  <InputLabel id="select-outlined-label">
                    {field.value === undefined && selectAgeLabel}
                  </InputLabel>
                  <Select
                    displayEmpty
                    labelId="select-outlined-label"
                    id="select-outlined"
                    className={classes.select}
                    {...field}
                    MenuProps={{ disablePortal: true }}
                  >
                    <MenuItem
                      {...field}
                      value="6a0dc94c-e985-4009-90a1-75822279a85a"
                    >
                      18-24
                    </MenuItem>
                    <MenuItem
                      {...field}
                      value="782ced81-b1d0-4caa-b0b2-c71e02f4bb82"
                    >
                      25-34
                    </MenuItem>
                    <MenuItem
                      {...field}
                      value="d6c23e7c-9cc3-4497-89d8-984ca3c288a9"
                    >
                      35-44
                    </MenuItem>
                    <MenuItem
                      {...field}
                      value="2c5d2095-3de5-483a-b779-6ac5bb984771"
                    >
                      45-54
                    </MenuItem>
                    <MenuItem
                      {...field}
                      value="9b0367c4-069a-41a8-b890-56753b6a9639"
                    >
                      55-64
                    </MenuItem>
                    <MenuItem
                      {...field}
                      value="858a210e-716a-42ef-9176-2a479c8438a3"
                    >
                      65+
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Typography variant="body2" className={classes.captchaDisclaimer}>
              {captchaDisclaimer}
            </Typography>

            <Grid container item justify="space-between">
              <Button
                onClick={() => handleBack()}
                className={classes.popupBackButton}
              >
                <img src={ArrowLeft} alt="ArrowLeft" />
                <Typography
                  variant="button"
                  className={classes.popupBackButtonText}
                >
                  {backButtonText}
                </Typography>
              </Button>
              <Button
                type="submit"
                className={classes.popupSubmitButton}
                onClick={executeCaptcha}
              >
                <Typography
                  variant="button"
                  className={classes.popupSubmitButtonText}
                >
                  {submitButtonText}
                </Typography>
                <img src={Send} alt="Send" />
              </Button>
            </Grid>
          </div>
        )}

        {step === 3 && (
          <Step3MessageSent
            onClickingAwayHandler={handleOnClickingAwayHandler}
            thankYouMessage={thankYouMessage}
            thankYouMessageBody={thankYouMessageBody}
            closeButtonText={closeButtonText}
          />
        )}
      </form>
    </>
  );
}
{
  /* <section>
<label>Your investable assets</label>
<Controller
  name="yourInvestableAssets"
  control={control}
  render={() => (
    <FormControl component="fieldset">
      <RadioGroup>
        <FormControlLabel
          value="£0-74k"
          control={<Radio />}
          label="£0-74k"
        />
        <FormControlLabel
          value="£75-300k"
          control={<Radio />}
          label="£75-300k"
        />
        <FormControlLabel
          value="£300k+"
          control={<Radio />}
          label="£300k+"
        />
      </RadioGroup>
    </FormControl>
  )}
/>
</section> */
}

export default connect((state) => ({}), null)(ContactForm);
